import styled from "styled-components";
import { FormControl } from "@mui/material";

export const StyledFormControlDropdown = styled(FormControl)`
    &.MuiFormControl-root {
        margin: 10px 30px 4px 0;
    }

    .MuiFormLabel-root {
        color: ${({ theme }) => theme.palette.textColor};
    }

    .MuiSelect-select {
        color: ${({ theme }) => theme.palette.textColor};
    }
    
    .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors.gray[900]};
    }

    .Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors.gray[900]};
    }

    &:hover .MuiOutlinedInput-notchedOutline {
        border-color: ${({ theme }) => theme.colors.gray[900]};
    }

    .MuiSvgIcon-root {
        color: ${({ theme }) => theme.palette.textColor};
    }
`;

import styled from "styled-components";
import { Card, CardContent, Typography } from "@mui/material";

interface StyledJobCardProps {
    $isSelected: boolean;
}

interface StyledJobCardContentProps {
    $isDisabled: boolean;
}

export const StyledJobCard = styled(Card)<StyledJobCardProps>`
    box-sizing: border-box;
    width: 220px;
    height: 116px;
    margin-bottom: 12px;
    cursor: pointer;
    background-color: ${(props) =>
        props.$isSelected
            ? props.theme.colors.indigo_dye[600]
            : props.theme.palette.primary};
    border-color: ${(props) =>
        props.$isSelected
            ? props.theme.colors.white
            : props.theme.colors.cool_gray};
`;

export const StyledJobCardContent = styled(CardContent)<StyledJobCardContentProps>`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 12px !important;

    .MuiBox-root {
        overflow: hidden;
    }

    .job-card-top {
        display: flex;

        .MuiIconButton-root {
            padding: 0px;
            margin-left: 5px;
        }
    }

    .job-card-actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: -4px;

        .MuiIconButton-root {
            color: ${({ theme }) => theme.colors.white};
            padding: 2px;
            margin-top: -2px;
        }
        
        .MuiCheckbox-root {
            padding: 2px;
            color: ${(props) => props.$isDisabled ? props.theme.colors.paynes_gray: props.theme.colors.gray[900] };
            
            &: hover {
                color: ${(props) => props.$isDisabled ? props.theme.colors.paynes_gray: props.theme.colors.white };
            }
        }

        .Mui-checked {
            color: ${({ theme }) => theme.colors.white};
        }
    }
`;

export const StyledTypography = styled(Typography)`
    color: ${({ theme }) => theme.colors.gray[900]};
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;

export const StyledTypographyTitle = styled(StyledTypography)`
    color: ${({ theme }) => theme.palette.textColor};
    font-weight: 600;
    margin-bottom: 4px;
    margin-top: 4px;
`;

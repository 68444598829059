import styled from "styled-components";
import { StyledDataGrid } from "../../Allocator.styled";

export const StyledJobList = styled(StyledDataGrid)`
    .MuiDataGrid-columnHeaders {
        visibility: hidden;
        border: none;
    }

    .MuiDataGrid-row:hover {
        background-color: transparent;
    }

    .MuiDataGrid-row:hover {
        background-color: transparent;
    }

    .MuiDataGrid-cell {
        padding: 0;
    }
`;

import { Box, Button, Modal, Tab, Tabs, Tooltip } from "@mui/material";
import { Attachment as AttachmentIcon, Explicit } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import CustomSnackbar from "src/components/CustomSnackbar/CustomSnackbar";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";
import { EFileService, AttachmentsService } from "src/services";
import {
    EfileData,
    FixMeLater,
    FolderNode,
    ReturnDocument,
    ReturnDocumentId,
    ValidationResponse,
    Attachment,
    EfileResponseData,
    TabProperties,
    ReturnNode,
    Tree,
} from "src/types";
import "./EFileModal.scss";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { useAppDispatch, useAppSelector } from "src/hooks";

export interface EFileModalProps {
    folderNode: FolderNode;
    returnNode: ReturnNode;
    returnDocument: ReturnDocument;
    open: boolean;
    validationResponse: ValidationResponse | null;
    tabProperties: TabProperties;
    onEfiled: () => void;
}

const EFileModal: React.FC<EFileModalProps> = ({
    folderNode,
    returnNode,
    returnDocument,
    open,
    validationResponse,
    tabProperties,
    onEfiled,
}) => {

    const dispatch = useAppDispatch();

    const [tab, setTab] = React.useState(0);
    const [efileData, setEfileData] = useState<EfileData>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<FixMeLater>(null);
    const [successMessage, setSuccessMessage] = useState<EfileResponseData>();

    const efileService = EFileService.getInstance();
    const attachmentsService = AttachmentsService.getInstance();

    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>("");
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("info");
    const handleSnackbar = (message: string, severity: string) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value,
    );

    const tree: Tree = useAppSelector(
        (state) => state[product?.productName]?.value?.tree,
    );

    const updateReturnNodeInTree = (newReturnNode: ReturnNode) => {
        console.log("newReturnNode", newReturnNode);
        const updatedTree = {
            ...tree,
            folderNodes: tree.folderNodes.map((folderNode) => ({
                ...folderNode,
                returnNodes: folderNode.returnNodes.map((node) =>
                    node.id === newReturnNode.id ? { ...newReturnNode } : node
                )
            }))
        };
        dispatch(
            GlobalStateActions[product?.productName].setTree(updatedTree)
        )
    }

    const returnDocId: Partial<ReturnDocumentId> = {
        companyId: returnDocument?.id?.companyId,
        folderId: returnDocument?.id?.folderId,
        productId: returnDocument?.id?.productId,
        taxYearId: returnDocument?.id?.taxYearId,
        moduleId: returnDocument?.id?.moduleId,
        returnId: returnDocument?.id?.returnId,
        retalFolderId: returnDocument?.id?.retalFolderId,
    };

    useEffect(() => {
        setError(null);
        if (
            open &&
            returnDocument &&
            validationResponse &&
            !validationResponse?.failures?.length
        ) {
            const fetchInfo = async () => {
                try {
                    setIsLoading(true);
                    setEfileData(
                        await efileService.fetchEfileData(
                            returnDocId as ReturnDocumentId,
                        ),
                    );
                } catch (error: FixMeLater) {
                    console.error("Error fetching data", error);
                    setError(error);
                } finally {
                    setIsLoading(false);
                }
            };
            fetchInfo();
        }
    }, [open, validationResponse]);

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
        id: string;
        controls: string;
    }

    const sendEfile = async () => {
        try {
            setIsLoading(true);
            const efileResponse: EfileResponseData =
                await efileService.sendEfileData(
                    returnDocId as ReturnDocumentId,
                );
                if (tabProperties) {
                    const updatedTabProperties: TabProperties = {
                        ...tabProperties,
                        submissionId: "efiled",
                    };
                    dispatch(GlobalStateActions.setTab(updatedTabProperties));
                    updateReturnNodeInTree(
                        {
                            ...returnNode,
                            submissionId: "efiled",
                        },
                    );
                    onEfiled();
                }
            handleSnackbar("E-File Sent!", "success");
            setSuccessMessage(efileResponse);
        } catch (error: FixMeLater) {
            const parsedError = JSON.parse(error.body);
            const errorMessage =
                parsedError.message || "An unknown error occurred";
            handleSnackbar(errorMessage, "success");
            console.error("Error sending Message:", error);
            setError(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    const getMails = (efileData: EfileData) => {
        if (!efileData.ccEmailAddress) return;
        return efileData.ccEmailAddress.split(";").map((mail, idx) => {
            return (
                <a key={`EfileCCEmail-${idx}`} href={`mailto:${mail}`}>
                    {mail}
                </a>
            );
        });
    };

    function TabPanel(props: TabPanelProps) {
        const { children, value, index, id, controls } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={controls}
                aria-labelledby={id}
            >
                {value === index && <>{children}</>}
            </div>
        );
    }

    const openAttachment = async (attachment: Attachment["attachment"]) => {
        let fileExt =
            attachment.fileName.split(".").pop()?.toLowerCase() || "pdf";
        const _attachment = await attachmentsService.getAttachment(
            attachment.fileIdentifier,
            fileExt,
        );

        const blob = new Blob([await _attachment.blob()], {
            type: `application/${fileExt}`,
        });

        const blobUrl = URL.createObjectURL(blob);

        window.open(blobUrl, "_blank");
    };

    const columns: GridColDef[] = [
        {
            field: "id",
            headerName: "ID",
            editable: false,
        },
        {
            field: "type",
            headerName: "File Type",
            editable: false,
            renderCell: (params) => {
                return (
                    <span>
                        {params.value === "SYSTEM_DEFINED" ? (
                            <Explicit />
                        ) : (
                            <AttachmentIcon />
                        )}
                    </span>
                );
            },
        },
        {
            field: "name",
            headerName: "File Name",
            editable: false,
            renderCell(params) {
                let data: Attachment["attachment"] = params.row._data;
                return (
                    <Tooltip title="Click to open document in a new tab">
                        <span
                            style={{ cursor: "alias" }}
                            onClick={() => {
                                openAttachment(params.row._data);
                            }}
                        >
                            {params.value}{" "}
                        </span>
                    </Tooltip>
                );
            },
        },
        {
            field: "description",
            headerName: "Description",
            flex: 1,
            renderCell(params) {
                let data: Attachment["attachment"] = params.row._data;
                return (
                    <span>
                        {params.value}{" "}
                        {data.required && (
                            <span style={{ color: "red" }}>*</span>
                        )}
                    </span>
                );
            },
            editable: false,
        },
    ];

    const getRows = (efileData?: EfileData) => {
        if (efileData && efileData.attachments?.length > 0) {
            return efileData.attachments.map((attachment) => {
                return {
                    id: attachment?.id,
                    type: attachment?.type,
                    name: attachment?.fileName,
                    description: attachment?.description,
                    _data: attachment,
                };
            });
        } else {
            return [];
        }
    };

    return (
        <div className="efile-modal" data-testid="e-file-modal">
            {successMessage ? (
                <div className="success-container">
                    <a
                        href={successMessage.returnInfo.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="success-link"
                    >
                        {successMessage.returnInfo.description}
                    </a>
                    <a
                        href={successMessage.paymentInfo.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="success-link"
                    >
                        {successMessage.paymentInfo.description}{" "}
                    </a>
                    <p className="success-confirmation">
                        {successMessage.submissionConfirmationMessage}
                    </p>
                </div>
            ) : error ? (
                <ErrorMessage error={`${error.toString()}`} />
            ) : !error && isLoading ? (
                <Loader />
            ) : (
                <div className="efile-modal-content">
                    {!validationResponse?.failures?.length ? (
                        <>
                            <Tabs
                                className="efile-tab-container"
                                orientation="vertical"
                                value={tab}
                                onChange={handleChange}
                            >
                                <Tab
                                    label="Instructions"
                                    id="efile-tab-instructions"
                                    aria-controls="efile-tabpanel-instructions"
                                />
                                <Tab
                                    label="Review Attachments"
                                    id="efile-tab-review"
                                    aria-controls="efile-tabpanel-review"
                                />
                            </Tabs>
                            <div className="tab-panel-content">
                                <TabPanel
                                    value={tab}
                                    index={0}
                                    id="efile-tab-instructions"
                                    controls="efile-tabpanel-instructions"
                                >
                                    <span className="efile-tabpanel-title">
                                        {folderNode.attributes.displayName}{" "}
                                        E-File
                                    </span>

                                    {efileData?.instructions ? (
                                        <div>
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        efileData?.instructions ||
                                                        "",
                                                }}
                                            ></div>

                                            <div className="section">
                                                <p className="part">
                                                    Confirmation Emails will be
                                                    sent to the following:
                                                </p>
                                                <p className="part">
                                                    {getMails(efileData)}
                                                </p>
                                                <p className="part">
                                                    To add additional email
                                                    address, add them via the
                                                    options menu.
                                                </p>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="section">
                                            <p className="part">
                                                E-filing{" "}
                                                <b>
                                                    {
                                                        folderNode.attributes
                                                            .displayName
                                                    }
                                                </b>
                                                .
                                            </p>
                                            <p className="part">
                                                To add additional attachments
                                                that serve as supporting
                                                documentation for this form,
                                                close this form and add the
                                                necessary attachments first.
                                                Then you may e-file this return.
                                            </p>
                                        </div>
                                    )}
                                </TabPanel>
                                <TabPanel
                                    value={tab}
                                    index={1}
                                    id="efile-tab-review"
                                    controls="efile-tabpanel-review"
                                >
                                    <span className="efile-tabpanel-title">
                                        {folderNode.attributes.displayName}{" "}
                                        E-File
                                    </span>

                                    <div className="efile-tabpanel-legend">
                                        <div className="efile-tabpanel-legend-item">
                                            <Explicit />
                                            <span className="description">
                                                E-File
                                            </span>
                                        </div>
                                        <div className="efile-tabpanel-legend-item">
                                            <AttachmentIcon />
                                            <span className="description">
                                                Attachment
                                            </span>
                                        </div>
                                        <div className="efile-tabpanel-legend-item">
                                            <span style={{ color: "red" }}>
                                                *
                                            </span>
                                            <span className="description">
                                                Required
                                            </span>
                                        </div>
                                    </div>

                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    >
                                        <DataGrid
                                            data-testid="attachments-table"
                                            rows={getRows(efileData)}
                                            columns={columns}
                                            disableRowSelectionOnClick
                                            columnVisibilityModel={{
                                                id: false,
                                            }}
                                        />
                                    </Box>
                                </TabPanel>
                                <Button
                                    startIcon={<Explicit />}
                                    className="efile-send-button"
                                    onClick={sendEfile}
                                    data-testid="efile-send-button"
                                >
                                    E-File
                                </Button>
                            </div>
                        </>
                    ) : (
                        <div className="efile-tabpanel-errors">
                            <span className="efile-tabpanel-title">
                                The following requirements have not been met.
                            </span>
                            <ul>
                                {validationResponse?.failures?.map(
                                    (response, index) => (
                                        <li key={`attachment${index}`}>
                                            {response.message}
                                        </li>
                                    ),
                                )}
                            </ul>
                        </div>
                    )}
                    <CustomSnackbar
                        open={snackbarOpen}
                        setOpen={setSnackbarOpen}
                        message={snackbarMessage}
                        severity={snackbarSeverity}
                    />
                </div>
            )}
        </div>
    );
};
export default EFileModal;

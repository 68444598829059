import React, { FC } from "react";
import { FormControlLabel, Switch, TextField } from "@mui/material";
import { FixMeLater } from "src/types";

interface MunicipalCompanyOptionsProps {
    formData: FixMeLater;
    onFormDataChange: (newData: FixMeLater) => void;
}

const MunicipalCompanyOptions: FC<MunicipalCompanyOptionsProps> = ({
    formData,
    onFormDataChange,
}) => {
    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        const fieldName = name.split(".")[1];
        onFormDataChange({
            municipal: {
                ...formData.jsonOptions.municipal,
                [fieldName]: checked,
            },
        });
    };
    const displayTwoOptions = false //Hide two options for Muni based on TTS3797
    return (
        <>
            <FormControlLabel
                control={
                    <Switch
                        checked={
                            formData?.jsonOptions?.municipal
                                ?.assessTaxOnPolicyholder
                        }
                        onChange={handleToggleChange}
                        name="municipal.assessTaxOnPolicyholder"
                    />
                }
                label="Assess the tax on the policyholder"
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={
                            formData?.jsonOptions?.municipal
                                ?.assessCollectionFee
                        }
                        onChange={handleToggleChange}
                        name="municipal.assessCollectionFee"
                    />
                }
                label="Assess a collection fee on the policyholder"
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={
                            formData?.jsonOptions?.municipal?.reportCityPremiums
                        }
                        onChange={handleToggleChange}
                        name="municipal.reportCityPremiums"
                    />
                }
                label="Report Code A and B city premiums to the county"
            />
            {displayTwoOptions && (
                <FormControlLabel
                    control={
                        <Switch
                            checked={
                                formData?.jsonOptions?.municipal?.generateMinimumTax
                            }
                            onChange={handleToggleChange}
                            name="municipal.generateMinimumTax"
                        />
                    }
                    label="Generate Minimum Tax when tax is rounded down to zero"
                />
            )}
            {displayTwoOptions && (
                <FormControlLabel
                    control={
                        <Switch
                            checked={
                                formData?.jsonOptions?.municipal
                                    ?.payTaxWithReconciliation
                            }
                            onChange={handleToggleChange}
                            name="municipal.payTaxWithReconciliation"
                        />
                    }
                    label="Pay tax amount with Annual Reconciliation when tax is less than the threshold amount"
                />
            )}
            {formData?.jsonOptions?.municipal?.payTaxWithReconciliation && (
                <TextField
                    label="Threshold Amount"
                    type="number"
                    value={formData?.jsonOptions?.municipal?.thresholdAmount}
                    onChange={(event) =>
                        onFormDataChange({
                            municipal: {
                                ...formData?.jsonOptions?.municipal,
                                thresholdAmount: event.target.value,
                            },
                        })
                    }
                />
            )}
        </>
    );
};

export default MunicipalCompanyOptions;

import styled from "styled-components";
import colors from "src/styles/colors.scss";

interface StyledDragAndDropProps {
    $isEmptyPage: boolean | undefined;
}

export const StyledDragAndDrop = styled("div")<StyledDragAndDropProps>`
    box-sizing: border-box;
    border-radius: 2px;
    width: 100%;
    height: 100%;
    min-height: 116px;
    cursor: pointer;
    background-color: ${(props) => 
        props.$isEmptyPage 
        ? colors.allocatorComponentBackgroundColor
        : props.theme.palette.primary
    };
    border: ${(props) => 
        props.$isEmptyPage
        ? "none" 
        : `1px solid ${props.theme.colors.white}`
    };
`;

export const StyledUploadContainer = styled("div")<StyledDragAndDropProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: ${(props) => 
        props.$isEmptyPage
        ? props.theme.colors.black
        : props.theme.palette.textColor
    };

    .MuiSvgIcon-root {
        color: ${(props) => 
            props.$isEmptyPage
            ? props.theme.palette.primary
            : props.theme.colors.white
        };
    }

    p {
        margin: 12px 0 0;
        text-align: center;
    }

    .upload-hint {
        color: ${({ theme }) => theme.colors.paynes_gray};
        font-size: 14px;
        margin-top: 4px;
    }
`;
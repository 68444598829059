import styled from "styled-components";
import { Chip, Tabs } from "@mui/material";

export const StyledTabs = styled(Tabs)`
    display: flex;
    padding: 20px;
    min-height: 32px !important; 
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .MuiTabs-scrollButtons {
        color: ${({ theme }) => theme.colors.white};
        border-radius: 16px;
        width: 32px;
        height: 32px;
        border: ${({ theme }) => `1px solid ${theme.colors.cool_gray}`};
        z-index: 100;
        background-color: ${({ theme }) => theme.colors.indigo_dye[600]};
        opacity: 1;
        box-shadow: ${({ theme }) => `0px 0px 14px 14px ${theme.palette.primary}`};
        transition: all 0.3s ease-out;

        &:first-child {
            margin-right: -32px;
        }

        &:last-child {
            margin-left: -32px;
        }

        &: hover {
            background-color: ${({ theme }) => theme.colors.indigo_dye[700]};
        }
    }
    
    .MuiTabs-scrollButtons.Mui-disabled {
        opacity: 0;
    }

    .MuiTabs-indicator {
        display: none;
        height: 0;
    }

    .MuiTab-root {
        padding: 0;
        min-height: 32px;
        min-width: 0;
        text-transform: none;
    }
`;

export const StyledChipTab = styled(Chip)`
    color: ${({ theme }) => theme.palette.textColor};
    background-color: ${({ theme }) => theme.palette.primary};
    border: ${({ theme }) => `1px solid ${theme.colors.cool_gray}`};
    margin: 0 2px;

    &: hover {
        background-color: ${({ theme }) => theme.colors.indigo_dye[600]};
    }
`;

export const SelectedStyledChipTab = styled(Chip)`
    color: ${({ theme }) => theme.palette.textColor};
    background-color: ${({ theme }) => theme.colors.indigo_dye[600]};
    border: ${({ theme }) => `1px solid ${theme.colors.white}`};
    margin: 0 2px;
`;

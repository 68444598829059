import styled from "styled-components";
import { GridToolbarContainer } from "@mui/x-data-grid";
import colors from "src/styles/colors.scss";

export const StyledReportToolbar = styled(GridToolbarContainer)`
    justify-content: space-between;
    margin-bottom: 12px;
    margin-right: 40px;
    margin-top: 5px;
    padding-top: 0;

    .MuiBox-root {
        display: flex;
        align-items: center;
    }

    .MuiBadge-badge {
        background-color: ${colors.primaryColor};
    }
`;

import styled from "styled-components";
import LoadingButton from "@mui/lab/LoadingButton";
import colors from "src/styles/colors.scss";
import { JOB_STATUS_TYPES } from "src/types";
import { ExpandMore, InfoOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

interface StyledJobStatusProps {
    $status: JOB_STATUS_TYPES;
}

export const StyledJobStatus = styled(LoadingButton)<StyledJobStatusProps>`
    border-radius: 100px;
    height: 24px;
    display: flex;
    align-items: center;
    box-shadow: none;
    text-transform: none;
    font-size: 13px;
    padding: 3px 10px;
    color: ${(props) =>
        props.$status !== JOB_STATUS_TYPES.PROCESSING
            ? colors.white
            : colors.primaryColor};
    background-color: ${(props) => {
        switch (props.$status) {
            case JOB_STATUS_TYPES.PROCESSING:
                return colors.allocatorComponentBackgroundColor;
            case JOB_STATUS_TYPES.PROCESSED:
                return props.theme.colors.indigo_dye[300];
            case JOB_STATUS_TYPES.ON_REVIEW:
                return "#E58F2A";
            case JOB_STATUS_TYPES.APPROVED:
                return "#3BA995";
            case JOB_STATUS_TYPES.CANCELED:
                return "#D32F2F";
            case JOB_STATUS_TYPES.FAILED:
                return "#D32F2F";
        }
    }};

    &: hover {
        box-shadow: none;
        background-color: ${(props) => {
            switch (props.$status) {
                case JOB_STATUS_TYPES.PROCESSING:
                    return colors.allocatorComponentBackgroundLightColor;
                case JOB_STATUS_TYPES.PROCESSED:
                    return props.theme.colors.indigo_dye[400];
                case JOB_STATUS_TYPES.ON_REVIEW:
                    return "#efa854";
                case JOB_STATUS_TYPES.APPROVED:
                    return "#3BA995";
                case JOB_STATUS_TYPES.CANCELED:
                    return "#D32F2F";
                case JOB_STATUS_TYPES.FAILED:
                    return "#D32F2F";
            }
        }};
    }

    .MuiButton-endIcon {
        margin-left: 4px;
    }
`;

export const StyledCircularProgress = styled(CircularProgress)`
    color: ${colors.primaryColor};
    margin-top: 1px;
`;

export const StyledInfoOutlined = styled(InfoOutlined)`
    font-size: 16px !important;
`;

export const StyledExpandIcon = styled(ExpandMore)`
    margin-top: 2px;
`;

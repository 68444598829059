import React, { FC, ReactNode, useEffect, useRef } from "react";
import {
    StyledLayout,
    StyledLayoutMain,
    StyledLayoutMainContent,
} from "./Layout.styled";
import { LayoutPane } from "../LayoutPane/LayoutPane";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { useDebouncedCallback } from "use-debounce";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { UserOptions } from "src/types";

interface LayoutProps {
    leftPane?: ReactNode;
    rightPane?: ReactNode;
    topPane?: ReactNode;
    bottomPane?: ReactNode;
    children: ReactNode;
}

/**
 * Layout component that serves as a container for the application's layout.
 *
 * @component
 * @example
 * return (
 *   <Layout>
 *     // children components
 *   </Layout>
 * )
 *
 * @param {LayoutProps} props - The props for the Layout component.
 * @returns {JSX.Element} The rendered Layout component.
 */
export const Layout: FC<LayoutProps> = ({
    leftPane,
    rightPane,
    topPane,
    bottomPane,
    children,
}) => {
    const DEFAULT_MIN_PANE_SIZE = 250;
    const DEFAULT_INITIAL_PANE_SIZE = 350;
    const DEFAULT_MAX_PANE_SIZE = 800;

    const dispatch = useAppDispatch();

    const defaultSizes: {
        minSize: number;
        maxSize: number;
        initialSize: number;
    } = {
        minSize: DEFAULT_MIN_PANE_SIZE,
        maxSize: DEFAULT_MAX_PANE_SIZE,
        initialSize: DEFAULT_INITIAL_PANE_SIZE,
    };

    const leftPaneRef = useRef<HTMLDivElement>(null);

    function calcMainContentWidth(
        leftPaneRef: React.RefObject<HTMLDivElement>
    ) {
        const computedStyle = leftPaneRef.current
            ? getComputedStyle(leftPaneRef.current)
            : null;
        if (computedStyle?.position === "relative") {
            return `calc(100% - ${computedStyle.width})`;
        }
        return "100%";
    }

    const userOptions: UserOptions = useAppSelector((state) => {
        return state.UserOptions.value;
    });

    const [mainContentWidth, setMainContentWidth] =
        React.useState<string>("100%");

    const [leftPanePinned, setLeftPanePinned] = React.useState<boolean>(false);

    const debouncedUserOptions = useDebouncedCallback(() => {
        const computedStyle = leftPaneRef.current
            ? getComputedStyle(leftPaneRef.current)
            : null;
        dispatch(
            GlobalStateActions.UserOptions.setLeftSidebarPinned(leftPanePinned)
        );
        dispatch(
            GlobalStateActions.UserOptions.setLeftSidebarSize(
                parseInt(computedStyle?.width || "0", 10)
            )
        );
    }, 1000);

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            setMainContentWidth(calcMainContentWidth(leftPaneRef));
            debouncedUserOptions();
        });

        if (leftPaneRef.current) {
            observer.observe(leftPaneRef.current);
        }

        return () => {
            if (leftPaneRef.current) {
                observer.unobserve(leftPaneRef.current);
            }
        };
    }, [leftPaneRef.current, leftPanePinned]);

    return (
        <StyledLayout>
            {
                // Left pane
                leftPane && (
                    <LayoutPane
                        ref={leftPaneRef}
                        position="left"
                        zIndex={3}
                        closeOnClickAway
                        pinned={
                            userOptions.globalPreferences
                                .leftSidebarPinned
                        }
                        onPinnedChange={(pinned) => {
                            setLeftPanePinned(pinned);
                        }}
                        {...defaultSizes}
                        initialSize={
                            userOptions.globalPreferences.leftSidebarSize
                        }
                    >
                        {leftPane}
                    </LayoutPane>
                )
            }
            <StyledLayoutMain width={mainContentWidth}>
                {
                    // Top pane
                    topPane && (
                        <LayoutPane position="top" zIndex={1} {...defaultSizes}>
                            {topPane}
                        </LayoutPane>
                    )
                }

                {/* Main Content */}
                <StyledLayoutMainContent>{children}</StyledLayoutMainContent>

                {
                    // Bottom pane
                    bottomPane && (
                        <LayoutPane
                            position="bottom"
                            zIndex={1}
                            {...defaultSizes}
                        >
                            {bottomPane}
                        </LayoutPane>
                    )
                }
            </StyledLayoutMain>

            {
                // Right pane
                rightPane && (
                    <LayoutPane position="right" zIndex={3} {...defaultSizes}>
                        {rightPane}
                    </LayoutPane>
                )
            }
        </StyledLayout>
    );
};

import React, { FC, useEffect, useState } from "react";
import { DataGrid, GridSortModel } from "@mui/x-data-grid";
import { GridColDef, GridFilterModel } from "@mui/x-data-grid";
import { Box } from "@mui/material";
import { AccountAdminActionsReportService } from "src/services/AdminToolActionsReportService";
import { ActionRecord } from "src/types";
import "./ActionsReport.scss";
import ErrorMessage from "src/components/ErrorMessage/ErrorMessage";
import Loader from "src/components/Loader/Loader";

const ActionsReport: FC = () => {
    const [actions, setActions] = useState<ActionRecord[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [sortModel, setSortModel] = useState<GridSortModel>([
        { field: "whenActionHappened", sort: "desc" },
    ]);
    const currentYear = new Date().getFullYear();
    const [filterModel, setFilterModel] = useState<GridFilterModel>({
        items: [
            {
                field: "whenActionHappened",
                operator: "contains",
                value: currentYear.toString(),
            },
        ],
    });

    const accountAdminActionsReportService =
        AccountAdminActionsReportService.getInstance();

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response =
                    await accountAdminActionsReportService.getActionsReport();
                setActions(response);
                setError(null);
            } catch (error) {
                console.error("Error fetching actions report:", error);
                setError(
                    "Failed to load actions report. Please try again later."
                );
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const columns: GridColDef[] = [
        { field: "actionType", headerName: "Action Type", width: 600 },
        { field: "whenActionHappened", headerName: "When", width: 180 },
        { field: "whoDidAction", headerName: "Who Did Action", width: 180 },
        { field: "traceId", headerName: "Trace ID", width: 180 },
        { field: "outcome", headerName: "Outcome", width: 120 },
    ];

    return (
        <div className="actions-report-container">
            {error ? (
                <ErrorMessage error={error} />
            ) : isLoading ? (
                <Loader color="white" />
            ) : (
                <Box sx={{ height: 800, width: "100%" }}>
                    <DataGrid
                        rows={actions}
                        columns={columns}
                        sortModel={sortModel}
                        onSortModelChange={(newModel) => setSortModel(newModel)}
                        filterModel={filterModel}
                        onFilterModelChange={(newModel) =>
                            setFilterModel(newModel)
                        }
                    />
                </Box>
            )}
        </div>
    );
};

export default ActionsReport;

import { setProduct } from "./ProductSlice";

import {
    setCompany as setPremiumCompany,
    setFolderNode as setPremiumFolderNode,
    setOptinsExport as setPremiumOptinsExport,
    setPaymentRequestExport as setPremiumPaymentRequestExport,
    setReturnDocument as setPremiumReturnDocument,
    setReturnNode as setPremiumReturnNode,
    setSelectedReturnPage as setPremiumReturnPage,
    setReturnTemplate as setPremiumReturnTemplate,
    setTree as setPremiumTree,
    setState as setPremiumState,
    setReturnNotes as setPremiumReturnNote,
    setSelectedJurisdictionForSBP,
    setCompanyOptionsOpen as setPremiumCompanyOptionsOpen,
    setSynchronizedScrollIsEnabled as setPremiumSynchronizedScrollIsEnabled,
    setSynchronizedScrollIsSynchronized as setPremiumSynchronizedScrollIsSynchronized,
    setSynchronizedScrollPosition as setPremiumSynchronizedScrollPosition,
} from "./PremiumSlice";

import {
    setSelectedReturnPage as setFormPlusReturnPage,
    setCompany as setFormsPlusCompany,
    setFolderNode as setFormsPlusFolderNode,
    setOptinsExport as setFormsPlusOptinsExport,
    setPaymentRequestExport as setFormsPlusPaymentRequestExport,
    setReturnDocument as setFormsPlusReturnDocument,
    setReturnNode as setFormsPlusReturnNode,
    setReturnTemplate as setFormsPlusReturnTemplate,
    setTree as setFormsPlusTree,
    setState as setFormsPlusState,
    setReturnNotes as setFormsPlusReturnNote,
    setCompanyOptionsOpen as setFormsPlusCompanyOptionsOpen,
    setSynchronizedScrollIsEnabled as setFormsPlusSynchronizedScrollIsEnabled,
    setSynchronizedScrollIsSynchronized as setFormsPlusSynchronizedScrollIsSynchronized,
    setSynchronizedScrollPosition as setFormsPlusSynchronizedScrollPosition,
} from "./FormsPlusSlice";

import {
    setSelectedReturnPage as setMunicipalReturnPage,
    setFolderNode as setMunicipalFolderNode,
    setReturnDocument as setMunicipalReturnDocument,
    setReturnNode as setMunicipalReturnNode,
    setPaymentRequestExport as setMunicipalPaymentRequestExport,
    setReturnTemplate as setMunicipalReturnTemplate,
    setSelectedState as setMunicipalSelectedState,
    setCompany as setMunicipalCompany,
    setSelectedQuarter as setMunicipalSelectedQuarter,
    setTree as setMunicipalTree,
    setToogleFetchSignature as setMunicipalToogleFetchSignature,
    setImportProgress as setMunicipalImportProgress,
    setReturnNotes as setMunicipalReturnNote,
    setCompanyOptionsOpen as setMunicipalCompanyOptionsOpen,
    setSynchronizedScrollIsEnabled as setMunicipalSynchronizedScrollIsEnabled,
    setSynchronizedScrollIsSynchronized as setMunicipalSynchronizedScrollIsSynchronized,
    setSynchronizedScrollPosition as setMunicipalSynchronizedScrollPosition,
} from "./MunicipalSlice";

import {
    setAccountOptions as setAgentAccountOptions,
    setAllocateAddressResult as setAgentAllocateAddressResult,
    setDocument as setAgentDocument,
    setEffectiveDate as setAgentEffectiveDate,
    setUsageReport as setAgentUsageReport,
    setUserOptions as setAgentUserOptions,
} from "./AgentSlice";

import { setProcessedProducts, setProducts } from "./ProductsSlice";

import { setSelf, setPendingChange, removePendingChange, setCompanyOptionChanges } from "./SelfSlice";

import {
    setTabs,
    setTab,
    removeTab,
    setSplitScreen,
    setToogleFetch,
} from "./TabsSlice";

import { setCompanies } from "./CompaniesSlice";
import { setStates } from "./StatesSlice";

import {
    setSelectedAccountRoles as setAccountAdminAccountRoles,
    setAccounts as setAccountAdminAccounts,
    setSelectedAccount as setAccountAdminSelectedAccount,
    setSelectedAccountCompanies as setAccountAdminSelectedAccountCompanies,
    setSelectedAccountCompany as setAccountAdminSelectedAccountCompany,
    setSelectedAccountCompanyGroups as setAccountAdminSelectedAccountCompanyGroups,
    setSelectedAccountCompanyProducts as setAccountAdminSelectedAccountCompanyProducts,
    setSelectedAccountCompanyUsers as setAccountAdminSelectedAccountCompanyUsers,
    setSelectedAccountGroup as setAccountAdminSelectedAccountGroup,
    setSelectedAccountGroupCompanies as setAccountAdminSelectedAccountGroupCompanies,
    setSelectedAccountGroupUsers as setAccountAdminSelectedAccountGroupUsers,
    setSelectedAccountGroups as setAccountAdminSelectedAccountGroups,
    setSelectedAccountProducts as setAccountAdminSelectedAccountProducts,
    setSelectedAccountRole as setAccountAdminSelectedAccountRole,
    setSelectedAccountSubWithCompanies as setAccountAdminSelectedAccountSub,
    setSelectedAccountUser as setAccountAdminSelectedAccountUser,
    setSelectedAccountUsers as setAccountAdminSelectedAccountUsers,
    setSelectedComponent as setAccountAdminSelectedComponent,
    setUsersWithCsrRoleId as setAccountAdminUsersWithCsrRoleId,
} from "./AccountAdminSlice";

import {
    setCorrectedAddresses as setAllocatorCorrectedAddresses,
    setEmptyCorrectedAddresses as setAllocatorEmptyCorrectedAddresses,
    setJobList as setAllocatorJobList,
    setJobsPage as setAllocatorJobsPage,
    setJobsFilterState as setAllocatorJobsFilterState,
    setIsJobSearchActive as setAllocatorIsJobSearchActive,
    setRemoveCorrectedAddress as setAllocatorRemoveCorrectedAddress,
    setReportTabs as setAllocatorReportTabs,
    setReportTabsLoading as setAllocatorReportTabsLoading,
    setSelectedFileName as setAllocatorSelectedFileName,
    setSelectedJob as setAllocatorSelectedJob,
    setSelectedJobStatus as setAllocatorSelectedJobStatus,
    setSelectedReportTab as setAllocatorSelectedReportTab,
    setToogleFetchJobs as setAllocatorToogleFetchJobs,
    setToogleFetchReport as setAllocatorToogleFetchReport,
    setUpdateSelectedJob as setAllocatorUpdateSelectedJob,
    setUpdatedJob as setAllocatorUpdatedJob,
    setReportsFilterState as setAllocatorReportsFilterState,
    setReportsPageSize as setAllocatorReportsPageSize,
    setEmptyReportsFilterState as setAllocatorEmptyReportsFilterState,
    setReportColumnsWidth as setAllocatorReportColumnsWidth,
    setReportColumns as setAllocatorReportColumns,
    setDownloadOptions as setAllocatorDownloadOptions,
    setExportOptions as setAllocatorExportOptions,
    setStateAbbr as setAllocatorStateAbbr,
    setCheckedJob as setAllocatorCheckedJob,
    setCompany as setAllocatorCompany,
} from "./AllocatorSlice";

import {
    setFilter,
    setFilterDropDownState,
    setDropdownStateView,
    setUserOptions,
    setZoomScale,
    setLeftSidebarPinned,
    setLeftSidebarSize,
} from "./UserOptionsSlice";

const GlobalStateActions = {
    setProduct,
    setSelf,
    setPendingChange,
    setCompanyOptionChanges,
    removePendingChange,
    setTabs,
    setTab,
    removeTab,
    setSplitScreen,
    setToogleFetch,
    setCompanies,
    setStates,
    Products: {
        setProcessedProducts,
        setProducts,
    },
    Premium: {
        setCompany: setPremiumCompany,
        setState: setPremiumState,
        setFolderNode: setPremiumFolderNode,
        setOptinsExport: setPremiumOptinsExport,
        setPaymentRequestExport: setPremiumPaymentRequestExport,
        setReturnDocument: setPremiumReturnDocument,
        setReturnTemplate: setPremiumReturnTemplate,
        setSelectedReturnPage: setPremiumReturnPage,
        setReturnNode: setPremiumReturnNode,
        setTree: setPremiumTree,
        setReturnNotes: setPremiumReturnNote,
        setSelectedJurisdictionForSBP: setSelectedJurisdictionForSBP,
        setCompanyOptionsOpen: setPremiumCompanyOptionsOpen,
        setSynchronizedScrollIsEnabled: setPremiumSynchronizedScrollIsEnabled,
        setSynchronizedScrollIsSynchronized:
            setPremiumSynchronizedScrollIsSynchronized,
        setSynchronizedScrollPosition: setPremiumSynchronizedScrollPosition,
    },
    FormsPlus: {
        setCompany: setFormsPlusCompany,
        setState: setFormsPlusState,
        setFolderNode: setFormsPlusFolderNode,
        setOptinsExport: setFormsPlusOptinsExport,
        setPaymentRequestExport: setFormsPlusPaymentRequestExport,
        setReturnDocument: setFormsPlusReturnDocument,
        setReturnTemplate: setFormsPlusReturnTemplate,
        setSelectedReturnPage: setFormPlusReturnPage,
        setReturnNode: setFormsPlusReturnNode,
        setTree: setFormsPlusTree,
        setReturnNotes: setFormsPlusReturnNote,
        setCompanyOptionsOpen: setFormsPlusCompanyOptionsOpen,
        setSynchronizedScrollIsEnabled: setFormsPlusSynchronizedScrollIsEnabled,
        setSynchronizedScrollIsSynchronized:
            setFormsPlusSynchronizedScrollIsSynchronized,
        setSynchronizedScrollPosition: setFormsPlusSynchronizedScrollPosition,
    },
    Agent: {
        setDocument: setAgentDocument,
        setUsageReport: setAgentUsageReport,
        setEffectiveDate: setAgentEffectiveDate,
        setAccountOptions: setAgentAccountOptions,
        setUserOptions: setAgentUserOptions,
        setAllocateAddressResult: setAgentAllocateAddressResult,
    },
    "Account Admin": {
        setAccounts: setAccountAdminAccounts,
        setSelectedAccount: setAccountAdminSelectedAccount,
        setSelectedAccountCompanies: setAccountAdminSelectedAccountCompanies,
        setSelectedAccountCompany: setAccountAdminSelectedAccountCompany,
        setSelectedAccountCompanyGroups:
            setAccountAdminSelectedAccountCompanyGroups,
        setSelectedAccountCompanyProducts:
            setAccountAdminSelectedAccountCompanyProducts,
        setSelectedAccountCompanyUsers:
            setAccountAdminSelectedAccountCompanyUsers,
        setSelectedAccountProducts: setAccountAdminSelectedAccountProducts,
        setSelectedAccountSubWithCompanies: setAccountAdminSelectedAccountSub,
        setSelectedAccountUser: setAccountAdminSelectedAccountUser,
        setSelectedAccountUsers: setAccountAdminSelectedAccountUsers,
        setSelectedAccountRole: setAccountAdminSelectedAccountRole,
        setSelectedAccountRoles: setAccountAdminAccountRoles,
        setSelectedComponent: setAccountAdminSelectedComponent,
        setSelectedAccountGroup: setAccountAdminSelectedAccountGroup,
        setSelectedAccountGroupCompanies:
            setAccountAdminSelectedAccountGroupCompanies,
        setSelectedAccountGroupUsers: setAccountAdminSelectedAccountGroupUsers,
        setSelectedAccountGroups: setAccountAdminSelectedAccountGroups,
        setUsersWithCsrRoleId: setAccountAdminUsersWithCsrRoleId,
    },
    Allocator: {
        setJobList: setAllocatorJobList,
        setUpdatedJob: setAllocatorUpdatedJob,
        setJobsPage: setAllocatorJobsPage,
        setToogleFetchJobs: setAllocatorToogleFetchJobs,
        setJobsFilterState: setAllocatorJobsFilterState,
        setIsJobSearchActive: setAllocatorIsJobSearchActive,
        setSelectedJob: setAllocatorSelectedJob,
        setUpdateSelectedJob: setAllocatorUpdateSelectedJob,
        setSelectedJobStatus: setAllocatorSelectedJobStatus,
        setSelectedFileName: setAllocatorSelectedFileName,
        setCorrectedAddresses: setAllocatorCorrectedAddresses,
        setRemoveCorrectedAddress: setAllocatorRemoveCorrectedAddress,
        setEmptyCorrectedAddresses: setAllocatorEmptyCorrectedAddresses,
        setToogleFetchReport: setAllocatorToogleFetchReport,
        setReportTabs: setAllocatorReportTabs,
        setReportTabsLoading: setAllocatorReportTabsLoading,
        setSelectedReportTab: setAllocatorSelectedReportTab,
        setReportsFilterState: setAllocatorReportsFilterState,
        setReportsPageSize: setAllocatorReportsPageSize,
        setEmptyReportsFilterState: setAllocatorEmptyReportsFilterState,
        setReportColumnsWidth: setAllocatorReportColumnsWidth,
        setReportColumns: setAllocatorReportColumns,
        setDownloadOptions: setAllocatorDownloadOptions,
        setExportOptions: setAllocatorExportOptions,
        setStateAbbr: setAllocatorStateAbbr,
        setCheckedJob: setAllocatorCheckedJob,
        setCompany: setAllocatorCompany,
    },
    Municipal: {
        setCompany: setMunicipalCompany,
        setSelectedState: setMunicipalSelectedState,
        setSelectedQuarter: setMunicipalSelectedQuarter,
        setTree: setMunicipalTree,
        setFolderNode: setMunicipalFolderNode,
        setReturnDocument: setMunicipalReturnDocument,
        setReturnTemplate: setMunicipalReturnTemplate,
        setSelectedReturnPage: setMunicipalReturnPage,
        setReturnNode: setMunicipalReturnNode,
        setPaymentRequestExport: setMunicipalPaymentRequestExport,
        setToogleFetchSignature: setMunicipalToogleFetchSignature,
        setImportProgress: setMunicipalImportProgress,
        setReturnNotes: setMunicipalReturnNote,
        setCompanyOptionsOpen: setMunicipalCompanyOptionsOpen,
        setSynchronizedScrollIsEnabled: setMunicipalSynchronizedScrollIsEnabled,
        setSynchronizedScrollIsSynchronized:
            setMunicipalSynchronizedScrollIsSynchronized,
        setSynchronizedScrollPosition: setMunicipalSynchronizedScrollPosition,
    },
    UserOptions: {
        setUserOptions,
        setFilter,
        setFilterDropDownState,
        setZoomScale,
        setDropdownStateView,
        setLeftSidebarPinned,
        setLeftSidebarSize
    },
};

export default GlobalStateActions;

import { BaseAPIService } from "src/services/BaseApiService";
import {
    AccessControlGroup,
    Account,
    Company,
    CreateAccessControlGroupPayload,
    FixMeLater,
    Product,
    Role,
    SubAccount,
    SubAccountWithCompanies,
} from "src/types";

export class AccountService {
    private static _instance: AccountService;
    private readonly BASE_PATH: string = "/api/account";
    private api: BaseAPIService = BaseAPIService.getInstance();

    constructor() {}

    public static getInstance(): AccountService {
        if (!AccountService._instance) {
            AccountService._instance = new AccountService();
        }
        return AccountService._instance;
    }

    private getPath(path: string): string {
        return `${this.BASE_PATH + path}`;
    }

    // GET

    getAllAccounts(): Promise<Account[]> {
        return this.api.get<Account[]>(this.getPath("/all"));
    }

    getAccountProducts(accountNumber: number): Promise<Product[]> {
        return this.api.get<Product[]>(
            this.getPath(`/${accountNumber}/products`)
        );
    }

    getAccountCompanies(accountNumber: string): Promise<Company[]> {
        return this.api.get<Company[]>(
            this.getPath(`/${accountNumber}/companies`)
        );
    }

    getAccountSubAccounts(accountNumber: number): Promise<SubAccount[]> {
        return this.api.get<SubAccount[]>(
            this.getPath(`/${accountNumber}/sub`)
        );
    }

    getAccountSubAccountsWithCompanies(
        accountNumber: number
    ): Promise<SubAccountWithCompanies[]> {
        return this.api.get<SubAccountWithCompanies[]>(
            this.getPath(`/${accountNumber}/subaccountsWithCompanies`)
        );
    }

    getAccountGroups(accountId: string): Promise<AccessControlGroup[]> {
        return this.api.get<AccessControlGroup[]>(
            this.getPath(`/${accountId}/accesscontrolgroup`)
        );
    }

    getAccountRoles(accountId: string): Promise<Role[]> {
        return this.api.get<Role[]>(this.getPath(`/${accountId}/roles`));
    }

    // POST

    createAccount(account: Account): Promise<Account> {
        return this.api.post<Account>(this.getPath(""), account);
    }

    createAccountGroup(
        accountId: string,
        group: CreateAccessControlGroupPayload
    ): Promise<AccessControlGroup> {
        return this.api.post<AccessControlGroup>(
            this.getPath(`/${accountId}/accesscontrolgroup`),
            group
        );
    }

    setProductsToAccount(products: FixMeLater[]): Promise<FixMeLater[]> {
        const accountId = products[0].accountId;
        return this.api.post<Product[]>(
            this.getPath(`/${accountId}/products`),
            products
        );
    }

    // PUT

    updateAccount(account: Account): Promise<Account> {
        return this.api.put<Account>(this.getPath(`/${account.id}`), account);
    }

    updateAccountGroupContent(
        group: AccessControlGroup,
        contentIndicator: string
    ): Promise<AccessControlGroup> {
        return this.api.put<AccessControlGroup>(
            this.getPath(
                `/${group.accountId}/accesscontrolgroup/${contentIndicator}`
            ),
            group
        );
    }

    // DELETE

    /**
     * Delete an access control group
     * @param group AccessControlGroup
     * @returns The number of the access control group that was deleted
     */
    deleteAccountGroup(group: AccessControlGroup): Promise<number> {
        return this.api.delete<number>(
            this.getPath(`/${group.accountId}/accesscontrolgroup/${group.id}`),
            group
        );
    }

    deleteAccountProduct(product: Partial<Product>): Promise<void> {
        return this.api.delete<void>(
            this.getPath(
                `/${product.accountId}/products/${product.productId}/taxYear/${product.taxYear}`
            ),
            product
        );
    }

    deactivateAccount(accountId: string) {
        return this.api.post<Account>(this.getPath(`/${accountId}`));
    }
}

import React from "react";
import { MainIcon, SubIcon, SupIcon } from "./CompositeIcon.styled";

export interface CompositeIconProps {
    icon: React.ReactNode;
    subIcon?: React.ReactNode;
    supIcon?: React.ReactNode;
    backgroundColor?: string;
}

export const CompositeIcon: React.FC<CompositeIconProps> = ({
    icon,
    subIcon,
    supIcon,
    backgroundColor = "#354560",
}) => {
    return (
        <MainIcon $backgroundColor={backgroundColor}>
            {icon}
            {subIcon && <SubIcon $backgroundColor={backgroundColor}>{subIcon}</SubIcon>}
            {supIcon && <SupIcon $backgroundColor={backgroundColor}>{supIcon}</SupIcon>}
        </MainIcon>
    );
};

import React from "react";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AllocatorJobsPayload, FixMeLater } from "src/types";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { StyledFormControlDropdown } from "./CompanyDropdown.styled";

interface CompanyDropdownProps {
    userCompanies: string[];
    lastSelectedCompany: string | undefined;
    isDragActive: boolean;
}

const CompanyDropdown: React.FC<CompanyDropdownProps> = ({
    userCompanies,
    lastSelectedCompany,
    isDragActive,
}) => {
    const product: FixMeLater = useAppSelector(
        (state) => state?.Product?.value
    );
    const selectedCompany: string = useAppSelector(
        (state) => state?.[product?.productName]?.value?.company,
    );
    const page: number = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.page
    );
    const filterState: AllocatorJobsPayload = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.filterState
    );

    const dispatch = useAppDispatch();

    const handleChange = (event: SelectChangeEvent) => {
        if (page) {
            dispatch(
                GlobalStateActions[product?.productName].setJobsPage(0)
            );
        }
        dispatch(
            GlobalStateActions[product?.productName].setCompany(
                event?.target?.value
            ),
        );
        if (filterState === undefined) {
            dispatch(
                GlobalStateActions[product?.productName].setJobsFilterState({ filters: [], searchPhrase: ""})
            );
        }
    };

    if (isDragActive) return null;

    return (
        <StyledFormControlDropdown size="small">
            <InputLabel>Company</InputLabel>
            <Select
                value={selectedCompany || lastSelectedCompany}
                label="Company"
                onChange={handleChange}
            >
                {
                    userCompanies?.map((company, index) => {
                        return (
                            <MenuItem value={company} key={index}>{company}</MenuItem>
                        )
                    })
                }
            </Select>
        </StyledFormControlDropdown>
    );
};

export default CompanyDropdown;

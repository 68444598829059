import React, { FC, MouseEvent, PropsWithChildren, useRef } from "react";
import { GridToolbarFilterButton } from "@mui/x-data-grid";
import { Box, ClickAwayListener, IconButton, Slide } from "@mui/material";
import { Search } from "@mui/icons-material";
import { useAppDispatch, useAppSelector } from "src/hooks";
import { AllocatorJob, FixMeLater } from "src/types";
import { StyledJobListToolbar, StyledJobsSearchActive } from "./JobListToolbar.styled";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";

interface JobListToolbar {
    isInputSearchFilled?: boolean;
    isDragActive?: boolean;
}

const JobListToolbar: FC<PropsWithChildren<JobListToolbar>> = ({ isInputSearchFilled, isDragActive }) => {
    const product: FixMeLater = useAppSelector((state) => state?.Product?.value);
    const jobList: AllocatorJob[] = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.jobList
    );
    const isAnyJobChecked = jobList?.some(job => job.checked);
    const isJobSearchActive: boolean = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.isJobSearchActive
    );

    const containerRef = useRef<HTMLElement>(null);
    const ref = useRef<HTMLDivElement>(null);

    const dispatch = useAppDispatch();

    const handleSearchActive = (e: MouseEvent) => {
        e.stopPropagation();
        dispatch(
            GlobalStateActions[product?.productName].setIsJobSearchActive(true)
        );
    };

    const handleClickAway = () => {
        if (isJobSearchActive && !isInputSearchFilled) {
            dispatch(
                GlobalStateActions[product?.productName].setIsJobSearchActive(false)
            );
        }
    };

    return (
        <StyledJobListToolbar $isDragActive={isDragActive} $isSearchActive={isJobSearchActive} $isDisabled={isAnyJobChecked}>
            <Box ref={containerRef}>
                <GridToolbarFilterButton slotProps={{ button: { sx: {marginRight: "10px"} } }}/>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Slide in={isJobSearchActive} direction="left" container={containerRef.current}>
                        {/* Wrapping element with div to avoid ref error */}
                        <div ref={ref}>
                            <StyledJobsSearchActive sx={{ width: isJobSearchActive? "100%" : "0px"}} $isDisabled={isAnyJobChecked}/>
                        </div>
                    </Slide>
                </ClickAwayListener>
                {
                    !isJobSearchActive && (
                        <IconButton sx={{ padding: "6px" }} onClick={handleSearchActive}>
                            <Search />
                        </IconButton>
                    )
                }
            </Box>
        </StyledJobListToolbar>
    );
};

export default JobListToolbar;

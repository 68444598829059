import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Product, UserOptions } from "src/types";

interface UserOptionsState {
    value: UserOptions;
}

const initialState: UserOptionsState = {
    value: {
        premiumType: "",
        schedulePreferences: [],
        widgetsLayout: "",
        productPreferences: {
            Premium: {
                filters: {
                    year: "",
                    company: "",
                    state: "",
                },
                dropdownStateView: false,
            },
            FormsPlus: {
                filters: {
                    year: "",
                    company: "",
                    state: "",
                },
                dropdownStateView: false,
            },
            Municipal: {
                filters: {
                    year: "",
                    company: "",
                    state: "",
                    quarter: "",
                },
                dropdownStateView: false,   
            },
        },
        globalPreferences: {
            theme: "tritech",
            zoom: 1,
            leftSidebarPinned: true,
            leftSidebarSize: 300
        },
    },
};

export const UserOptionsSlice = createSlice({
    name: "UserOptions",
    initialState,
    reducers: {
        setFilter: (
            state,
            action: PayloadAction<{
                product: Product;
                filters?: Record<string, string>;
                dropdownStateView: boolean;
            }>,
        ) => {
            const productName = action.payload.product.productName;

            // Initialize product preferences if not present
            if (!state.value.productPreferences[productName]) {
                state.value.productPreferences[productName] = {
                    filters: action.payload.filters || {},
                    dropdownStateView: action.payload.dropdownStateView,
                };
            } else {
                // Merge existing filters with new filters
                const existingFilters =
                    state.value.productPreferences[productName].filters || {};
                state.value.productPreferences[productName].filters = {
                    ...existingFilters,
                    ...action.payload.filters,
                };
                state.value.productPreferences[productName].dropdownStateView =
                    action.payload.dropdownStateView;
            }
        },
        setFilterDropDownState: (
            state,
            action: PayloadAction<{
                product: Product;
                dropdownStateView: boolean;
            }>,
        ) => {
            const productName = action.payload.product?.productName;

            // Initialize product preferences if not present
            if (!state.value.productPreferences[productName]) {
                state.value.productPreferences[productName] = {
                    dropdownStateView: action.payload.dropdownStateView,
                };
            } else {
                state.value.productPreferences[productName].dropdownStateView =
                    action.payload.dropdownStateView;
            }
        },
        setZoomScale: (state, action: PayloadAction<number>) => {
            console.log(action.payload);
            state.value.globalPreferences.zoom = action.payload;
        },
        setUserOptions: (state, action: PayloadAction<UserOptions>) => {
            state.value = action.payload;
        },
        setLeftSidebarPinned: (state, action: PayloadAction<boolean>) => {
            state.value.globalPreferences.leftSidebarPinned = action.payload
        },
        setLeftSidebarSize: (state, action: PayloadAction<number>) => {
            state.value.globalPreferences.leftSidebarSize = action.payload
        },
        setDropdownStateView: (
            state,
            action: PayloadAction<{
                productName: string;
                dropdownStateView: boolean;
            }>,
        ) => {
            const productName = action.payload.productName;

            // Check if productPreferences and product exist, if not, initialize them
            if (!state.value.productPreferences[productName]) {
                state.value.productPreferences[productName] = {
                    dropdownStateView: false,
                };
            }
            state.value.productPreferences[productName].dropdownStateView =
                action.payload.dropdownStateView;
        },
    },
});

// Exporting actions and reducer
export const {
    setUserOptions,
    setFilterDropDownState,
    setDropdownStateView,
    setZoomScale,
    setFilter,
    setLeftSidebarPinned,
    setLeftSidebarSize,
} = UserOptionsSlice.actions;
export default UserOptionsSlice.reducer;

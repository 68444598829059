import React, { FC, MouseEvent, useState } from "react";
import "./BulkExport.scss";
import { StyledDarkOutlinedButton, StyledDarkTextButton, StyledTextButton } from "src/products/Allocator/Allocator.styled";
import { AllocatorExportOption, AllocatorJob, AllocatorJobsExportOptions, FixMeLater } from "src/types";
import { useAppDispatch, useAppSelector } from "src/hooks";
import GlobalStateActions from "src/redux/slices/GlobalStateActions";
import { AllocatorService } from "src/services";
import { downloadFile } from "src/utils";
import { useSnackbar } from "notistack";
import { Menu, MenuItem } from "@mui/material";

interface BulkExportProps {
    isDragActive: boolean;
    exportOptions?: AllocatorJobsExportOptions;
}

const BulkExport: FC<BulkExportProps> = ({ isDragActive, exportOptions }) => {
    const allocatorService = AllocatorService.getInstance();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const product: FixMeLater = useAppSelector((state) => state?.Product?.value);
    const jobList: AllocatorJob[] = useAppSelector(
        (state) => state?.[product?.productName]?.value?.jobs?.jobList
    );
    const checkedJobState = jobList?.find(job => job.checked)?.stateAbbr;
    const checkedJobIds = jobList?.reduce((filtered: number[], job: AllocatorJob) => {
        if (job?.checked) {
            filtered.push(job?.id)
        }
        return filtered;
    }, []);

    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useAppDispatch();

    const handleExport = async (event: MouseEvent<HTMLElement>) => {
        handleCloseMenu(event);
        setIsLoading(true);

        try {
            const reportDownloadable = await allocatorService.generateSelectedReports(
                checkedJobIds,
                String(event?.currentTarget?.dataset?.name),
            );
            const { blob, contentDispositionHeader } = reportDownloadable;
            downloadFile(blob, contentDispositionHeader.split("filename=")[1]);
            enqueueSnackbar("Exported successfully", { variant: "success" });
        } catch (error) {
            enqueueSnackbar("Failed to download reports", { variant: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancel = () => {
        dispatch(
            GlobalStateActions[product?.productName].setJobList(
                jobList?.map(job => {
                    return {
                        ...job,
                        checked: false
                    }
                })
            )
        );
    };

    const handleClickOpen = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setAnchorEl(event?.currentTarget);
    };

    const handleCloseMenu = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };

    if (!checkedJobIds?.length || isDragActive) return null;

    return (
        <div className="bulk-export-container">
            <StyledDarkTextButton
                disabled={isLoading}
                onClick={handleCancel}
            >
                Cancel
            </StyledDarkTextButton>
            <StyledDarkOutlinedButton
                variant="outlined"
                loading={isLoading}
                onClick={handleClickOpen}
            >
                Export ({checkedJobIds?.length})
            </StyledDarkOutlinedButton>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                onClose={handleCloseMenu}
                onContextMenu={handleCloseMenu}
            >
                {!!checkedJobState && exportOptions?.[checkedJobState]?.map(
                    (option: AllocatorExportOption, index: number) => {
                        return (
                            <MenuItem
                                key={index}
                                onClick={handleExport}
                                data-name={option?.name}
                            >
                                {option?.displayName}
                            </MenuItem>
                        )
                    }
                )}
            </Menu>
        </div>
    );
};

export default BulkExport;

import styled from 'styled-components';

export const StyledLayout = styled.div`
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    width: 100%;
    height: 100%;
    min-width: 100%;
    min-height: 100%;
    position: relative;
`;

export const StyledLayoutMain = styled.div.attrs<{width: string}>(({ width }) => ({
    style: {
        width,
        minWidth: width,
    },
}))<{width: string}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-height: 100%;
    position: relative;
    background-color: ${({theme}) => theme.colors.white}; 
`;


export const StyledLayoutMainContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;
    position: relative;
`;

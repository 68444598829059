import React, { FC, MouseEvent, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import { useSnackbar } from "notistack";
import { AllocatorService } from "src/services";
import { ALLOCATOR_SEARCH_PARAMS, AllocatorReportExportOption, FixMeLater } from "src/types";
import { useAppSelector } from "src/hooks";
import { StyledDarkOutlinedButton } from "src/products/Allocator/Allocator.styled";
import { downloadFile } from "src/utils";

const ReportExportButton: FC = () => {
    const allocatorService = AllocatorService.getInstance();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchParams] = useSearchParams();
    const jobId = searchParams.get(ALLOCATOR_SEARCH_PARAMS.JOB_ID);
    const product: FixMeLater = useAppSelector((state) => state?.Product?.value);
    const exportOptions: AllocatorReportExportOption[] = useAppSelector(
        (state) => state?.[product?.productName]?.value?.exportOptions
    );

    const { enqueueSnackbar } = useSnackbar();

    const handleClickOpen = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();

        if (exportOptions?.length === 1 && exportOptions[0]?.name) {
            handleExportReport(exportOptions[0].name);
        } else setAnchorEl(event?.currentTarget);
    };

    const handleCloseMenu = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(null);
    };

    const handleOptionClick = async (event: MouseEvent<HTMLElement>) => {
        handleCloseMenu(event);
        handleExportReport(String(event?.currentTarget?.dataset?.name));
    };

    const handleExportReport = async (exportParam: string) => {
        setIsLoading(true);
        try {
            const reportDownloadable = await allocatorService.generateImpReport(
                exportParam,
                {
                    jobId: Number(jobId),
                },
            );
            const { blob, contentDispositionHeader } = reportDownloadable;
            downloadFile(blob, contentDispositionHeader.split("filename=")[1]);
            enqueueSnackbar("Exported successfully", { variant: "success" });
        } catch (error) {
            enqueueSnackbar("Failed to download report", { variant: "error" });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <StyledDarkOutlinedButton
                sx={{marginRight: "24px"}}
                variant="outlined"
                loading={isLoading}
                disabled={!exportOptions?.length}
                onClick={handleClickOpen}
            >
                Export .imp
            </StyledDarkOutlinedButton>
            <Menu
                anchorEl={anchorEl}
                open={!!anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleCloseMenu}
                onContextMenu={handleCloseMenu}
            >
                {exportOptions?.map(
                    (option: AllocatorReportExportOption, index: number) => {
                        return (
                            <MenuItem
                                key={index}
                                onClick={handleOptionClick}
                                data-name={option.name}
                            >
                                {option.displayName}
                            </MenuItem>
                        );
                    }
                )}
            </Menu>
        </>
    );
};

export default ReportExportButton;
